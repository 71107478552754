import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import './UploadModal.css';
import { Checkbox, FormControlLabel, Input, styled } from '@material-ui/core';
import { toast } from 'react-toastify';
import Joyride from 'react-joyride';
import useWindowDimensions from '../../utiles/getWindowDimensions';
import { UpdateToken } from '../../helper/UpdateToken';
import { PlansContext } from '../../context/plans/plans';
import close from '../../assets/images/close-icon.png';
import api from '../../api';

const UploadModal = (props) => {
  const {
    handleClose,
    show,
    setUploadTextShow,
    handleChangeUploadFile,
    handleChangeUploadFileInBackground,
    handleUploadFile,
    loading,
    textCheckBox,
    setTextCheckBox,
    merge,
    setMerge,
    searchQuery,
    setSearchQuery,
    entireWebsite,
    setEntireWebsite,
    pagination,
    setPagination,
    noOfPages,
    setNoOfPages,
    defaultValues,
    conId,
    data,
    setData,
    filesSelected,
    setFilesSelected,
  } = props;
  const CustomCheckbox = styled(Checkbox)`
    color: green; /* Replace with your desired shade of green */
    &:focus {
      background-color: green; /* Replace with your desired focus color */
    }
    &.Mui-checked {
      color: green; /* Replace with your desired shade of green */
    }
  `;
  const [savingCheck, setSavingCheck] = useState(false);

  useEffect(() => {
    setUploadText('');

    if (show) {
      setTextCheckBox(false);
      if (defaultValues) {
        setTextCheckBox(Boolean(defaultValues?.processUrls)); //processUrl
        setMerge(Boolean(defaultValues?.merge));
        setSearchQuery(Boolean(defaultValues?.search));
        setModel(defaultValues?.model);
        if (Boolean(defaultValues?.processUrls)) {
          const Pageinate =
            userPlan?.[0]?.subType?.Pagination ||
            userPlan?.[0]?.subscriptionType?.Pagination;
          if (Pageinate === 1) {
            setPagination(Boolean(defaultValues?.Pagination));
          }
          const EntireSite =
            userPlan?.[0]?.subType?.entireWebsite ||
            userPlan?.[0]?.subscriptionType?.entireWebsite;
          if (EntireSite === 1) {
            setEntireWebsite(Boolean(defaultValues?.entireWebsite));
          }
        }
        defaultValues?.pagination_max_iteractions &&
          setNoOfPages(defaultValues?.pagination_max_iteractions);
      } else {
        setMerge(false);
      }
    }
  }, [show, data]);

  const [returnRowsLimit, setReturnRowsLimit] = useState(0);
  const [model, setModel] = useState(1);
  const [uploadText, setUploadText] = useState('');
  const { userPlan } = useContext(PlansContext);
  // const joyrideOptions = {
  //   // Other options...
  //   styles: {
  //     options: {
  //       // Style the beacon
  //       beacon: {
  //         backgroundColor: 'blue',
  //         color: 'white',
  //         fontSize: '16px',
  //         // Other styles...
  //       },
  //       // Other styles...
  //     },
  //   },
  // };
  // const [steps, setSteps] = useState([
  //   {
  //     index: 0,
  //     type: 'step:before',
  //     target: '#upload_file_convert_background_label',
  //     placementBeacon: 'bottom',

  //     content: (
  //       <p>
  //         After clicking this button you will be prompted to select a file to be
  //         converted according to the sample you uploaded previously.If you want
  //         to convert data from websites by providing URLs within the file you’re
  //         uploading, please BEFORE upload check the checkbox “Process URLs” at
  //         the bottom of the form. The number of URLs you can process depends on
  //         your subscription.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 1,
  //     target: '#textareastep',
  //     placement: 'right-end',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         In the text field, you can directly copy text. For example from email,
  //         messenger, or a website.If you want to convert data from websites by
  //         providing URLs within the text box (for example
  //         “https://www.producthunt.com/ https://www.g2.com/ …”), please BEFORE
  //         hitting the button “TEXT” check the checkbox “Process URLs” at the
  //         bottom of the form.The number of URLs you can process depends on your
  //         subscription.
  //       </p>
  //     ),
  //   },

  //   {
  //     index: 2,
  //     target: '#step3btn_background',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         When the text in the text box is ready, you can submit it to the
  //         conversion by hitting this button. Text should be no less than 10
  //         characters to be submitted.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 3,
  //     target: '#stepfour',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         When enabled, the “Process URLs” checkbox allows extraction of all
  //         URLs from uploaded file or text and convert these URLs 1-by-1.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 4,
  //     target: '#stepfive',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         When enabled, the “Smart Merge” checkbox will tell conversion to merge
  //         the intelligent rows after conversion to one row. For example, if
  //         conversion returns more than one row of data: “Smart Merge” UNchecked
  //         result - 4 rows: - Row 1. Value: “US Tesla Stores and Galleries” Row
  //         2. “Tesla Stores in Puerto Rico” Row 3. “Tesla Stores in Mexico” Row
  //         4. “Tesla Stores in Canada” “Smart Merge” checked result - 1 row : -
  //         Row 1. “US Tesla Stores and Galleries, Tesla Stores in Puerto Rico,
  //         Tesla Stores in Mexico, Tesla Stores in Canada” If you are processing
  //         URLs, each URL will be merged separately. For example, for 2 website
  //         URLs, there will be always 2 rows of results when “Smart Merge” is
  //         checked.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 5,
  //     target: '#stepsix',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         You can define the maximum number of rows the conversion will return.
  //         For example, if you know that from the exact file or text or website,
  //         you need exactly or no more than 5 rows, by defining 5 in “Return rows
  //         limit” you will get no more than 5 rows as a result. This sometimes
  //         makes sense to get results quicker. Set “Return rows limit” to 0 for
  //         the unlimited number of rows in the result. The number of rows in the
  //         result can be limited within your subscription even if you’re setting
  //         0.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 6,
  //     target: '#stepseven',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         “AI model” is a technical parameter for our AI unit. Please leave it’s
  //         value equal to 1.
  //       </p>
  //     ),
  //   },
  // ]);

  // const onJoyrideCallback = (data) => {
  //   if (data.index === 6 && data.lifecycle === 'complete') {
  //   }
  // };
  const updateCheck = async (label, value) => {
    try {
      setSavingCheck(true);
      await api
        .put(`/conversion/updateConversionCheck/${conId}`, {
          label,
          value: Number(value),
        })
        .then((res) => {
          setData(res?.data);
          setSavingCheck(false);
        });
    } catch (e) {
      console.log('Error updating checks in conversion: ', e);
      setSavingCheck(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-center" style={{ fontSize: '24px' }}>
          Transform from
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Joyride
          steps={steps}
          continuous
          run={true}
          hideCloseButton
          callback={onJoyrideCallback}
          // scrollToFirstStep
          showSkipButton
          showProgress
          styles={{
            options: {
              // arrowColor: '#e3ffeb',
              // backgroundColor: '#e3ffeb',
              // overlayColor: 'rgba(0, 0, 0, 0.377)',
              // primaryColor: '#000',
              // textColor: '#004a14',
              // width: 900,
              zIndex: 1500,
            },
          }}
        /> */}
        {/* <h1 className='hello'>hello im muaz</h1> */}

        <div className="d-flex mt-3">
          <FormControlLabel
            className="d-flex align-items-center"
            control={
              <CustomCheckbox
                id="stepfour"
                defaultChecked={textCheckBox}
                disabled={savingCheck}
                onChange={(e) => {
                  setTextCheckBox(e.target.checked);
                  updateCheck('processUrls', e.target.checked);
                }}
              />
            }
            disabled={filesSelected?.length > 1}
            label="Process URLs. Extract all URLs from uploaded files and text and process URLs 1-by-1. Each URL will count as one transformation"
          />
          <FormControlLabel
            className="d-flex align-items-center  ms-2"
            control={
              <CustomCheckbox
                id="stepfive"
                defaultChecked={merge}
                disabled={savingCheck}
                onChange={(e) => {
                  setMerge(e.target.checked);
                  updateCheck('merge', e.target.checked);
                }}
              />
            }
            label="Merge results to one row (If enabled, it will return only one merged row of data)"
          />
        </div>
        {textCheckBox && ( //textCheckBox
          <div className="d-flex mt-3 align-items-start">
            <div className="d-flex flex-column gap-2 w-50">
              <FormControlLabel
                className="d-flex align-items-center"
                control={
                  <CustomCheckbox
                    id="stepPaginate"
                    checked={pagination}
                    disabled={savingCheck}
                    onChange={(e) => {
                      const Pageinate =
                        userPlan?.[0]?.subType?.Pagination ||
                        userPlan?.[0]?.subscriptionType?.Pagination;

                      if (!Pageinate || Pageinate === 0) {
                        toast('Upgrade your subscription', { type: 'error' });
                      } else {
                        if (Pageinate === 1) {
                          updateCheck('Pagination', e.target.checked);
                          setPagination(e.target.checked);
                        }
                      }
                    }}
                  />
                }
                label="Pagination, Load More, Unlimited Scroll"
              />
              {pagination && (
                <div className="mb-3" id="stepPages">
                  <span className="text-format">Number of pages for pagination, scroll, load more: </span>
                  <Input
                    type="number"
                    value={noOfPages}
                    minlength={2}
                    disabled={savingCheck}
                    onChange={(e) => {
                      if (e.target.value < 2) {
                        toast(`Value should be greater then or equal to 2`, {
                          type: 'warning',
                        });
                      } else {
                        setNoOfPages(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      updateCheck('pagination_max_iteractions', e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            <FormControlLabel
              className="d-flex align-items-center  ms-2"
              control={
                <CustomCheckbox
                  id="stepEntireSite"
                  checked={entireWebsite}
                  disabled={savingCheck}
                  onChange={(e) => {
                    const EntireSite =
                      userPlan?.[0]?.subType?.entireWebsite ||
                      userPlan?.[0]?.subscriptionType?.entireWebsite;

                    if (!EntireSite || EntireSite === 0) {
                      toast('Upgrade your subscription', { type: 'error' });
                    } else {
                      if (EntireSite === 1) {
                        updateCheck('entireWebsite', e.target.checked);
                        setEntireWebsite(e.target.checked);
                      }
                    }
                  }}
                />
              }
              label="Entire Website (up to 5 pages. For more pages, please contact us)"
            />
          </div>
        )}
        <div className="d-flex mt-3">
          <FormControlLabel
            className="d-flex align-items-center"
            control={
              <CustomCheckbox
                id="stepnain"
                defaultChecked={searchQuery}
                disabled={savingCheck}
                onChange={(e) => {
                  updateCheck('search', e.target.checked);
                  setSearchQuery(e.target.checked);
                }}
              />
            }
            label="Smart AI Data Enrichment (for up to 10 first rows. For more rows contact us)"
          />
        </div>
        <div className="d-flex">
          {/* <div className="mb-3 " id="stepsix">
            <span id="sss" className="text-format">
              Return rows limit (0 - unlimited):{' '}
            </span>
            <Input
              type="number"
              value={returnRowsLimit}
              onChange={(e) => {
                setReturnRowsLimit(e.target.value);
              }}
            />
          </div> */}

          {JSON.parse(localStorage.getItem('user'))?.isDeveloper ? (
            <div className="mb-3 " id="stepseven">
              <span className="text-format">AI model: </span>
              <Input
                type="number"
                value={model}
                disabled={savingCheck}
                onChange={(e) => {
                  if (e.target.value < 0) {
                    toast(`Value should be greater then 0`, {
                      type: 'warning',
                    });
                  } else {
                    updateCheck('model', e.target.value);
                    setModel(e.target.value);
                  }
                }}
              />
            </div>
          ) : (
            <div className="mb-3" />
          )}
        </div>
        <div className="d-flex mb-3 justify-content-center align-items-center">
          <Form.Control
            className="rounded-0 uploadField"
            type="file"
            name="upload_file"
            id="upload_file_convert"
            onChange={(e) => {
              handleChangeUploadFile(e, returnRowsLimit, model, false);
            }}
            disabled={loading === '0' ? true : false}
            multiple={false}
            accept=".csv,.txt,.json,.docx,.png,.jpg,.jpeg,.xlsx,.html,.htm,.wav,.pptx,.xbrl,.xml,.pdf"
          />
          {/* <label
            for="upload_file_convert"
            id="stepone"
            className={`${
              loading === '0'
                ? 'upload_file_convert_btn upload_file_convert_btn_online disabled'
                : 'upload_file_convert_btn upload_file_convert_btn_online'
            } `}
          >
            {loading === '0' ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              'File (need to wait)'
            )}
          </label> */}

          {/* Background button/input */}
          <Form.Control
            className="rounded-0 uploadField"
            type="file"
            name="upload_file_background"
            id="upload_file_convert_background"
            onChange={(e) => {
              const tempFiles = e.target.files;
              if (
                Object.values(tempFiles)?.some(
                  (file) => file?.size / 1024 / 1024 > userPlan[0]?.maxFileSize,
                ) &&
                userPlan?.length > 0
              ) {
                toast(
                  `Maximum file size should be ${userPlan[0]?.maxFileSize} MB`,
                  {
                    type: 'warning',
                  },
                );
              } else {
                setFilesSelected([...filesSelected, ...tempFiles]);
              }
              e.target.value = null;
            }}
            disabled={loading === '1' ? true : false}
            multiple={!textCheckBox}
            accept=".csv,.txt,.json,.docx,.png,.jpg,.jpeg,.xlsx,.html,.htm,.wav,.pptx,.xbrl,.xml,.pdf"
          />
          <div className="d-flex justify-content-between ">
            <label
              htmlFor="upload_file_convert_background"
              id="upload_file_convert_background_label"
              className={
                loading.background
                  ? 'upload_file_convert_btn disabled text-center'
                  : 'upload_file_convert_btn text-center'
              }
            >
              {'Upload File'}
            </label>
            <p
              style={{
                color: '#000000',
                fontSize: '14px',
                margin: 0,
              }}
            >
              TXT,CSV,HTML,PDF, Images,
              <br />
              PPTX, DOCX, XLSX, JSON,
              <br />
              XML/XBRL, Audio
            </p>
          </div>
          <Form.Control
            className="rounded-0 uploadField "
            type="file"
            name="upload_file"
            id="upload_file_convert2"
            onChange={(e) => {
              if (e.target.files?.length > 0) {
                handleUploadFile(
                  e.target.files[0],
                  '2',
                  returnRowsLimit,
                  model,
                );
                handleClose();
              }
            }}
            multiple={false}
            accept=".csv,.txt,.json,.docx,.png,.jpg,.jpeg,.xlsx,.html,.htm,.wav,.pptx,.xbrl,.xml,.pdf"
          />
          {/*<label for="upload_file_convert2" className="upload_file_convert_btn">
            {loading === "2" ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              'URLs'
            )}
            </label>*/}
        </div>
        <div className="d-flex flex-column mb-3 w-100">
          {filesSelected?.length > 0 &&
            filesSelected.map((file, index) => (
              <div key={index} className="d-flex w-100 justify-content-between">
                <p className="m-0">{file?.name}</p>
                <img
                  src={close}
                  alt="close"
                  className=""
                  style={{ cursor: 'pointer' }}
                  width={16}
                  height={16}
                  onClick={() => {
                    const temp = [...filesSelected];
                    temp.splice(index, 1);
                    setFilesSelected(temp);
                  }}
                />
              </div>
            ))}
        </div>
        <div>
          <textarea
            name="uploadText"
            onChange={(e) => {
              setUploadText(e.target.value);
            }}
            style={{
              border: '1px solid #E2E2EA',
              borderRadius: '10px',
              padding: '15px',
              width: '100%',
            }}
            rows="10"
            minlength="5"
            id="textareastep"
            placeholder="Paste any URLs or text here to transform it. 
            
            If you want to transform websites, remember to enable 'ProcessURLs' checkbox."
          >
            {uploadText}
          </textarea>
          <div
            style={{
              background: '#F1F1F5',
              borderRadius: '11px',
              // width: '260px',
              textAlign: 'center',
              color: '#44444F',
              fontSize: '14px',
              // whiteSpace: 'nowrap',
              flexWrap: 'wrap',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '1px',
              paddingBottom: '1px',
              position: 'absolute',
              marginTop: '-285px',
              marginLeft: '30px',
            }}
          >
            Text or website links (minimum 10 characters)
          </div>
          {/* {uploadText?.length < 10 && (
            <h5 className="text-center" style={{ fontSize: "14px" }}>
              minimum 10 - characters
            </h5>
          )} */}

          <div className="d-flex justify-content-center gap-3">
            {/* <Button
              id="step3btn"
              variant="secondary"
              disabled={
                uploadText?.length < 10
                  ? true
                  : false ||
                    returnRowsLimit === '' ||
                    model === '' ||
                    loading === '3'
              }
              onClick={() => {
                const file = new File([uploadText], 'text.txt', {
                  type: 'text/plain',
                });
                handleUploadFile(file, '3', returnRowsLimit, model, false);
              }}
              className={
                'upload_file_convert_btn upload_file_convert_btn_online btn-light'
              }
            >
              {loading === '3' ? (
                <Spinner animation="border" variant="secondary" />
              ) : (
                'Text (need to wait)'
              )}
            </Button> */}
            {/* <Button
              variant="dark"
              id="step3btn_background"
              disabled={
                uploadText?.length < 10
                  ? true
                  : false ||
                    returnRowsLimit === '' ||
                    model === '' ||
                    loading === '3'
              }
              onClick={() => {
                const file = new File([uploadText], 'text.txt', {
                  type: 'text/plain',
                });
                handleUploadFile(file, '1', returnRowsLimit, model, true);
                handleClose();
              }}
              className={'upload_file_convert_btn'}
            >
              {'Transform Text'}
            </Button> */}
            <div className="d-flex justify-content-between ">
              <button
                disabled={
                  (filesSelected?.length === 0 && !uploadText) ||
                  (uploadText && uploadText.length < 10)
                }
                className={`border-0 ${
                  loading.background
                    ? 'upload_file_convert_btn disabled text-center'
                    : 'upload_file_convert_btn text-center'
                }`}
                onClick={() => {
                  console.log('psps');
                  if (uploadText) {
                    const file = new File([uploadText], 'text.txt', {
                      type: 'text/plain',
                    });
                    const temp = [...filesSelected, file];
                    handleUploadFile(temp, '1', returnRowsLimit, model, true);
                  } else {
                    handleUploadFile(
                      filesSelected,
                      '1',
                      returnRowsLimit,
                      model,
                      true,
                    );
                  }
                  handleClose();
                }}
              >
                {'Transform'}
              </button>
            </div>
            {/* <label
              className="upload_file_convert_btn mt-2"
              onClick={() => {
                handleClose();
                setUploadTextShow(true);
              }}
            >
              Text
            </label> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
